import React, { forwardRef } from "react";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";

import { XPosition, YPosition } from "./dragTypes";

const DRAG_HANDLE_RADIUS = 5;
const DRAG_HANDLE_BORDER_WIDTH = 2;
const DRAG_HANDLE_AFFORDANCE_WIDTH = 16;
const DRAG_HANDLE_AFFORDANCE_HEIGHT = 16;

export interface DragHandlePosition {
  xPosition: XPosition;
  yPosition: YPosition;
}

const getCursor = (xPosition: XPosition, yPosition: YPosition): string => {
  if (xPosition === "middle") {
    return "ns-resize";
  } else if (yPosition === "middle") {
    return "ew-resize";
  } else if (
    (xPosition === "left" && yPosition === "bottom") ||
    (xPosition === "right" && yPosition === "top")
  ) {
    return "nesw-resize";
  } else {
    return "nwse-resize";
  }
};

const getLeftRightPosition = (
  xPosition: XPosition,
): FlattenSimpleInterpolation => {
  switch (xPosition) {
    case "left":
      return css`
        left: ${(-1 * DRAG_HANDLE_AFFORDANCE_WIDTH) / 2}px;
      `;
    case "middle":
      return css`
        left: calc(50% - ${DRAG_HANDLE_AFFORDANCE_WIDTH / 2}px);
      `;
    case "right":
      return css`
        right: ${(-1 * DRAG_HANDLE_AFFORDANCE_WIDTH) / 2}px;
      `;
  }
};

const getTopBottomPosition = (
  yPosition: YPosition,
): FlattenSimpleInterpolation => {
  switch (yPosition) {
    case "top":
      return css`
        top: ${(-1 * DRAG_HANDLE_AFFORDANCE_HEIGHT) / 2}px;
      `;
    case "middle":
      return css`
        top: calc(50% - ${DRAG_HANDLE_AFFORDANCE_HEIGHT / 2}px);
      `;
    case "bottom":
      return css`
        bottom: ${(-1 * DRAG_HANDLE_AFFORDANCE_HEIGHT) / 2}px;
      `;
  }
};

const DragHandleAffordanceDiv = styled.div<{
  xPosition: XPosition;
  yPosition: YPosition;
  visible: boolean;
  fullSizeAffordance: boolean;
}>`
  position: absolute;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${DRAG_HANDLE_AFFORDANCE_WIDTH}px;
  height: ${DRAG_HANDLE_AFFORDANCE_HEIGHT}px;

  cursor: ${({ xPosition, yPosition }) => getCursor(xPosition, yPosition)};
  ${({ xPosition }) => getLeftRightPosition(xPosition)}
  ${({ yPosition }) => getTopBottomPosition(yPosition)}
  ${({ visible }) => (visible ? "opacity: 1;" : "opacity: 0;")}

  ${({ fullSizeAffordance, xPosition, yPosition }) =>
    fullSizeAffordance && xPosition === "middle"
      ? "left: 0; width: 100%;"
      : fullSizeAffordance && yPosition === "middle"
        ? "top: 0; height: 100%;"
        : undefined}
`;

export const DragHandleUI = styled.span`
  display: block;
  width: ${2 * DRAG_HANDLE_RADIUS}px;
  height: ${2 * DRAG_HANDLE_RADIUS}px;

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
  border: ${DRAG_HANDLE_BORDER_WIDTH}px solid
    ${({ theme }) => theme.dragAndDrop.selectedElementBorderColor};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export interface ResizeDragHandleProps {
  position: DragHandlePosition;
  visible: boolean;
  fullSizeAffordance?: boolean;
}

export const ResizeDragHandle = forwardRef<
  HTMLDivElement,
  ResizeDragHandleProps
>(function ResizeDragHandle(props, ref) {
  const {
    fullSizeAffordance = false,
    position: { xPosition, yPosition },
    visible,
  } = props;

  return (
    <DragHandleAffordanceDiv
      ref={ref}
      fullSizeAffordance={fullSizeAffordance}
      visible={visible}
      xPosition={xPosition}
      yPosition={yPosition}
    >
      <DragHandleUI />
    </DragHandleAffordanceDiv>
  );
});
